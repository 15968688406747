<template>
    <div id="app">
        <router-view />
        <div @click="goTo()" class="fixed-left-corner">
          <img class="szechenyi-szoc" src="/assets/img/logo/szechenyi-szocialis.png" alt="">
        </div>
        <div @click="goTo()" class="fixed-right-corner">

          <img class="szechenyi" src="/assets/img/logo/szechenyi-transparent.png" alt="">
        </div>
    </div>
</template>

<script>
    export default {
      methods: {
        goTo() {
          this.$router.push("/szechenyi")
        }
      }
    }
</script>

<style scoped>
  .fixed-right-corner {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 10;
  cursor: pointer;
  }

  .fixed-left-corner {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 10;
  cursor: pointer;
  }

  .szechenyi {
    min-height: 150px;
  }

  .szechenyi-szoc {
    min-height: 70px;
  }
  
  @media (min-width:320px)  { 
    .szechenyi {
    height: 150px;
    width: auto;
  } 

  .szechenyi-szoc {
    height: 100px;
    width: auto;
  } 
  }
@media (min-width:481px)  { /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */ }
@media (min-width:641px)  { /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */ }
@media (min-width:961px)  { 
  .szechenyi {
    height: 200px;
  }
  
  .szechenyi-szoc {
    height: 130px;
  }
  }
@media (min-width:1025px) { 
  .szechenyi {
    height: 350px;
  }
  .szechenyi-szoc {
    height: 215px;
  } 
  }

</style>